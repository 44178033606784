<template>
	<div id="privacy" class="pb-0">
		<v-row class="pa-0 ma-0" align="center" no-gutters>
			<v-col cols="12" class="px-0 primary bg-wavelines-secondary-dark white--text">
				<div class="mx-auto mb-5 pb-5" id="how" style="max-width: 1200px;">
					<h2 class="display-1 mt-md-5 pt-10 mb-1 text-center text-md-h3 shadowed">Media &amp; Press Assets</h2>
					<p class="title mb-0 lh-14 text-center mx-auto mb-3 mb-md-5 shadowed" style="max-width: 600px;">
						I&rsquo;mOkay.me
					</p>
				</div>
			</v-col>
		</v-row>
		<v-row class="white mt-0">
			<v-col cols="12">
				<div class="mx-auto mb-5 pb-5 px-5 px-md-1 pt-md-10 secondary--text" id="how" style="max-width: 1000px;">
					<v-row>
						<v-col cols="12">
							<h1 class="text-h4">
								Sharing I&rsquo;mOkay.me
							</h1>
						</v-col>
						<v-col cols="12">
							<h4>Welcome!</h4>
							<p>We&rsquo;re excited you&rsquo;ve taken an interest in sharing information about I&rsquo;mOkay.me! To assist you, we&rsquo;ve provided a number of assets and copy to help you publish. Need more? Please contact us as <a href="mailto:support@imokay.me" target="_blank">support@imokay.me</a>.</p>
							<p>Jump to: <span class="primary--text font-weight-medium action-item" @click="$vuetify.goTo('#logos', {container:'body'});">Logos</span>, <span class="primary--text font-weight-medium action-item" @click="$vuetify.goTo('#banners', {container:'body'});">Banners</span>, 
							<span class="primary--text font-weight-medium action-item" @click="$vuetify.goTo('#copy', {container:'body'});">Copy</span>, <span class="primary--text font-weight-medium action-item" @click="$vuetify.goTo('#promos', {container:'body'});">Request Promo Codes</span>.</p>
						</v-col>
					</v-row>
					<v-divider class="mb-5" id="logos" />
					<v-row>
						<v-col cols="12">
							<h1 class="text-h4">
								I&rsquo;mOkay.me Logos
							</h1>
						</v-col>
						<v-col cols="12">
							<v-row>
								<v-col cols="12" md="6">
									<v-card color="secondary">
										<v-card-text class="text-center">
											<img src="https://imokay.s3.amazonaws.com/assets/logos/dark/imokayme-logo-dark.svg" alt="I'm Okay Logo" width="90%" />
										</v-card-text>
										<v-card-actions class="card-actions">
											<span class="body-2">Links: <a href="https://imokay.s3.amazonaws.com/assets/logos/dark/imokayme-logo-dark.png" target="_blank" class="font-weight-medium">PNG</a>, <a href="https://imokay.s3.amazonaws.com/assets/logos/dark/imokayme-logo-dark.svg" target="_blank" class="font-weight-medium">SVG</a></span>
											<v-spacer></v-spacer>
											<v-btn color="primary" href="https://imokay.s3.amazonaws.com/assets/logos/imokayme-logos.zip" target="_blank" class="px-3"><v-icon small class="mr-3">fa fa-download</v-icon> Download</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
								<v-col cols="12" md="6">
									<v-card color="white">
										<v-card-text class="text-center">
											<img src="https://imokay.s3.amazonaws.com/assets/logos/light/imokayme-logo-light.svg" alt="I'm Okay Logo" width="90%" />
										</v-card-text>
										<v-card-actions class="card-actions">
											<span class="body-2">Links: <a href="https://imokay.s3.amazonaws.com/assets/logos/light/imokayme-logo-light.png" target="_blank" class="font-weight-medium">PNG</a>, <a href="https://imokay.s3.amazonaws.com/assets/logos/light/imokayme-logo-light.svg" target="_blank" class="font-weight-medium">SVG</a></span>
											<v-spacer></v-spacer>
											<v-btn color="primary" href="https://imokay.s3.amazonaws.com/assets/logos/imokayme-logos.zip" target="_blank" class="px-3"><v-icon small class="mr-3">fa fa-download</v-icon> Download</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-divider class="mt-10 mb-5" id="banners" />
					<v-row>
						<v-col cols="12">
							<h1 class="text-h4">
								I&rsquo;mOkay.me Banners
							</h1>
						</v-col>
						<v-col cols="12">
							<v-row>
								<v-col cols="12">
									<v-card color="secondary">
										<v-card-text class="text-center">
											<v-img src="https://imokay.s3.amazonaws.com/assets/banners/imoakay-banner-rect-blue.jpg" alt="I'm Okay Logo" contain height="350px" />
										</v-card-text>
										<v-card-actions class="card-actions">
											<span class="body-2">1600x920px &bull; 82kb &bull; Links: <a href="https://imokay.s3.amazonaws.com/assets/banners/imoakay-banner-rect-blue.jpg" target="_blank" class="font-weight-medium">JPG</a></span>
											<v-spacer></v-spacer>
											<v-btn color="primary" href="https://imokay.s3.amazonaws.com/assets/banners/imoakay-banner-rect-blue.zip" target="_blank" class="px-3"><v-icon small class="mr-3">fa fa-download</v-icon> Download</v-btn>
										</v-card-actions>
									</v-card>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-divider class="mt-10 mb-5" id="copy" />
					<v-row>
						<v-col cols="12">
							<h1 class="text-h4">
								I&rsquo;mOkay.me Copy
							</h1>
							<p class="body-2 mb-0">No attribution required.</p>
						</v-col>
						<v-col cols="12">
							<h4>URL:</h4>
							<p>https://imokay.me</p>
							<h4>What is I&rsquo;mOkay?</h4>
							<p>
								ImOkay.me is a personal safety app that sends check-in texts to your phone. If you do not respond to a check-in, ImOkay.me alerts 
								your emergency contacts and shares any important information you wish to be relayed to them - including medical information, family and 
								pet care, property instructions, and other details that may help others help you in your time of need.
							</p>
							<p>
								Created during the Covid pandemic, wherein personal and professional contact became limited for many people, ImOkay.me helps provide a 
								layer of awareness to your well-being to those who care about you. Designed for those who live alone, single parents or sole caregivers, 
								and anyone else who needs others to know when they may need help - ImOkay.me offers a solution. 
							</p>
							<p>
								In short - ImOkay.me helps answer the question: "Who would know if you never made it home last night?".
							</p>
							<p>
								You can try ImOkay.me with a 20-day free trial. No credit card needed to sign up and cancelling your account is hassle-free. After your trial, 
								you can continue using the service for only $3/month.
							</p>
							<h4>How does I&rsquo;mOkay work?</h4>
							<p>
								ImOkay.me sends check-in texts to your phone on a schedule you choose that best fits your lifestyle. From 1 check-in per week to 3 per day 
								at any time you choose. You reply back with any message ("k" will do)  to let us know you're okay. If ImOkay.me does not receive a response 
								in an hour (or more, if you choose), it will send an alert message to your designated emergency contacts. The alert message asks your contacts 
								to try and get in touch with you and contains a link to an alert page created especially for you that can be shared.
							</p>
							<p>
								Along with an alert, you can share emergency notes with your contacts including medical information, family and 
								pet care, property instructions, and other details that may help others help you in your time of need. These notes are only viewable by your 
								designated contacts and require text-based verification to view.
							</p>
							<p>
								This alert system helps create a safety layer and provides a fallback in the event you encounter an emergency and have no way to 
								contact others for help.
							</p>
							<h4>What other features does I&rsquo;mOkay have?</h4>
							<p>
								ImOkay.me does not require an app or even a smartphone to use. Any device capable of sending and receiving text messages will work. Most 
								tools offered through the web app are also offered via a text-based interface.
							</p>
							<p>
								In addition to your scheduled check-ins, ImOkay.me also lets you add one-time check-ins. These can be useful for first dates and other 
								potential stranger dangers.
							</p>
							<p>
								Unplugging for a bit? ImOkay.me lets you snooze your check-ins for up to 15 days, after which they will automatically resume. Plan on being 
								away from your phone longer? You can also completely disable your check-ins until you manually resume them.
							</p>
							<p class="mb-0">
								ImOkay.me also lets your share any of your emergency notes at any time, sending a text message to the recipient with a secure link that requires 
								text-based authentication for every view. For example, if your flight is delayed you can quickly send all of your pet's care information to a 
								contact with a few clicks or even a few texts if you don't have internet access.
							</p>
						</v-col>
					</v-row>
					<v-divider class="mt-10 mb-5" id="promos" />
					<v-row>
						<v-col cols="12">
							<h1 class="text-h4">
								Request Promo Codes
							</h1>
						</v-col>
						<v-col cols="12">
							<h4>Want to offer your readers a custom promotional discount code?</h4>
							<p>
								ImOkay.me is relatively new and looking to add more users in effort to create a better product. While all users can enjoy a free 20-day trial, 
								we're happy to offer you a limited amount of custom promotional codes to entice and reward your readers. 
							</p>
							<p>
								To request your promo codes, please contact <a href="mailto:support@imokay.me" target="_blank">support@imokay.me</a> and let us know your publication's 
								name, your desired promo code (e.g. "MyBrandDiscount") and a link to the website on which you wish to share the code with your readers. We will randomly 
								select a discount ranging from 25% off for 6 months to 100% off for a year and supply you with between 10 and 50 one-time use codes depending on current 
								demand.
							</p>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>

export default {
	name: 'Terms',
	data: () => ({
		
	}),
	components: {
	},
	computed: {
	},
	methods: {
		initData(){
		},
	},
	beforeDestroy(){
	},
	mounted(){
		this.initData();
	},
	watch: {
		
	}
}
</script>